import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"

const FourOFourLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
`

const FourOFourContent = styled.div`
  display: flex;
  flex-direction: column;
`

const FourOFourBigRedNumbers = styled.p`
  color: #493B9A;
  font-weight: bold;
  font-size: 10rem;
  margin: 0;
  text-align: center;
`

const FourOFourInfoBox = styled.div`
  padding-top: 2rem;
  font-size: 1.2rem;
`

const FourOFourInfoRow = styled.p`
  text-align: center;
  padding: 0 0.3rem;
`


export default function FourOFour() {

  const createPageTitle = ()  => {
    return typeof window !== 'undefined' 
      ? `404 - ${window.location.pathname}`
      : "404"
  }

  return (
    <FourOFourLayout>
      <Helmet
        title={createPageTitle()}
      />
      <FourOFourContent>
        <FourOFourBigRedNumbers>
          404
        </FourOFourBigRedNumbers>
        <FourOFourInfoBox>
          <FourOFourInfoRow>
            Unfortunately, this page does not exist.
          </FourOFourInfoRow>
          <FourOFourInfoRow>
            Click <Link to={"/"}>here</Link> to go back to the home page.
          </FourOFourInfoRow>
        </FourOFourInfoBox>
      </FourOFourContent>
    </FourOFourLayout>
  )
}
